import React from 'react';
import useSWR from 'swr';
import { fetcher } from '../../Common/helpers/fetcher';
import moment from 'moment';
import { useState } from 'react';
import { DatePicker, Typography, Row, Col, Card, Divider } from 'antd';

import { AuthLayout } from '../../Common/partials/Layout';
import { Column, Line } from '@ant-design/charts';
import CardTitle from '../../Common/partials/CardTitle';
import CardBodyStats from '../../Common/partials/CardBody';
import Loading from '../../Common/partials/Loading';
import { Queries as HuntQueries } from '../../Common/queries/hunt';
import { useQuery } from '@apollo/client';
import { coreGraphQLClient } from '../../Common/api/apollo';

const { Title } = Typography;

const BILLING_ENDPOINT = process.env.REACT_APP_BILLING_API || 'http://localhost:4003';

const { RangePicker } = DatePicker;

function useAnalyticsWithinTimeRange(dateFilter: IDateRange | null) {
    let url = `${BILLING_ENDPOINT}/analytics`;
    if (dateFilter) {
        url = `${url}?startDate=${dateFilter?.startDate}&endDate=${dateFilter?.endDate}`;
    }

    const { data, error } = useSWR(url, fetcher, {
        refreshInterval: 30000,
    });
    return {
        data: data?.body,
        isLoading: !error && !data,
        isError: error,
    };
}

const dateFormat = 'YYYY-MM-DD';

const currentMonthDate = {
    startDate: moment().startOf('month').format(dateFormat),
    endDate: moment().endOf('month').format(dateFormat),
};

const incomeBreakdownChart = {
    xField: 'date',
    yField: 'totalAmount',
    meta: {
        date: { alias: 'Date' },
        totalAmount: { alias: 'Sales' },
    },
};

const planBreakdownChart = {
    xField: 'code',
    yField: 'count',
    meta: {
        code: { alias: 'Plan' },
        count: { alias: 'Subscriptions' },
    },
};

const countryBreakdownChart = {
    xField: 'name',
    yField: 'count',
    meta: {
        name: { alias: 'Country' },
        count: { alias: 'Count' },
    },
};

const cityBreakdownChart = {
    xField: 'name',
    yField: 'count',
    meta: {
        name: { alias: 'City' },
        count: { alias: 'Count' },
    },
};

const timeSeriesConfig = {
    xField: 'date',
    yField: 'count',
    seriesField: 'status',
    xAxis: {
        type: 'time',
    },
};

interface IDateRange {
    startDate: string;
    endDate: string;
}

export default function DashboardPage() {
    const [dateRange, setDateRange] = useState<IDateRange>(currentMonthDate);

    const [huntDateRange, setHuntDateRange] = useState<IDateRange>(currentMonthDate);
    const { data, isError, isLoading } = useAnalyticsWithinTimeRange(dateRange);

    const {
        data: huntAnalytics,
        loading,
        refetch,
    } = useQuery(HuntQueries.fetchHuntCandidateAnalytics, {
        variables: {
            filters: {
                startDate: huntDateRange.startDate,
                endDate: huntDateRange.endDate,
            },
        },
    });

    const {
        data: huntAnalyticsCandidateStatus,
        loading: huntAnalyticsCandidateStatusLoading,
        refetch: huntAnalyticsCandidateRefetch,
    } = useQuery(HuntQueries.fetchHuntCandidateStatusAnalytics, {
        client: coreGraphQLClient,
        variables: {
            filters: {
                startDate: huntDateRange.startDate,
                endDate: huntDateRange.endDate,
            },
        },
    });

    console.log(`huntAnalyticsCandidateStatus ==>`, huntAnalyticsCandidateStatus);

    const handleDateChange = (_: unknown, dates: string[]) => {
        console.log(`args ==>`, dates);

        // handle clear data
        if (dates[0].length > 0) {
            setDateRange({
                startDate: dates[0],
                endDate: dates[1],
            });
        }
    };

    const handleHuntDateChange = (_: unknown, dates: string[]) => {
        console.log(`args ==>`, dates);

        // handle clear data
        if (dates[0].length > 0) {
            setHuntDateRange({
                startDate: dates[0],
                endDate: dates[1],
            });
        }
    };

    // @ts-ignore
    const getProducts = (data: Record<string, any>): [] => {
        return data?.productCodeBreakdown?.length > 0 ? data.productCodeBreakdown : data.productCodeOverallBreakdown;
    };

    const enumerateDaysBetweenDates = function (startDate: moment.Moment, endDate: moment.Moment): string[] {
        var now = startDate,
            dates = [];

        while (now.isSameOrBefore(endDate)) {
            dates.push(now.format(dateFormat));
            now.add(1, 'days');
        }
        return dates;
    };

    // @ts-ignore
    const getIncomeBreakdown = (data: Record<string, any>): [] => {
        const startDate = moment(dateRange.startDate);
        const endDate = moment(dateRange.endDate);

        let rangeType;
        const dates = [];
        let breakdown = [];

        if (endDate.diff(startDate, 'days') <= 60) {
            rangeType = 'days';
            dates.push(...enumerateDaysBetweenDates(startDate, endDate));
        }
        // else if (endDate.diff(startDate, "months") >= 3) {
        //   rangeType = "weeks";
        //   // group by weeks
        //   // sort by week
        //   // find weeks betwen date
        // }

        breakdown = dates.map((dt) => {
            const info: Record<string, any> = {};

            const datePoint = data?.dayIncomeBreakdown?.find((x: Record<string, any>) => x.date == dt);

            if (datePoint) {
                info.date = datePoint.date;
                info.totalAmount = datePoint.totalAmount;
            } else {
                info.date = dt;
                info.totalAmount = 0;
            }

            return info;
        });

        console.log(`breakdown ==>`, breakdown);

        return breakdown.length > 0 ? breakdown : data?.dayIncomeBreakdown;
    };

    const getCampaignAndHuntStats = (breakdown: any): any[] => {
        const keys = Object.keys(breakdown);

        const data: any[] = [];
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];

            if (key == '__typename') continue;

            const stats = breakdown[key];
            stats.map((stat: any) => {
                if (key == 'newApplications') {
                    data.push(
                        ...[
                            {
                                type: 'New',
                                ...stat,
                            },
                            // {
                            //     type: 'Rejected',
                            //     ...stat,
                            //     count: 0,
                            // },
                            // {
                            //     type: 'Accepted',
                            //     ...stat,
                            //     count: 0,
                            // },
                        ],
                    );
                } else if (key == 'rejectedApplications') {
                    data.push(
                        ...[
                            // {
                            //     type: 'New',
                            //     ...stat,
                            //     count: 0,
                            // },
                            {
                                type: 'Rejected',
                                ...stat,
                            },
                            // {
                            //     type: 'Accepted',
                            //     ...stat,
                            //     count: 0,
                            // },
                        ],
                    );
                } else if (key == 'acceptedApplications') {
                    data.push(
                        ...[
                            // {
                            //     type: 'New',
                            //     ...stat,
                            //     count: 0,
                            // },
                            // {
                            //     type: 'Rejected',
                            //     ...stat,
                            //     count: 0,
                            // },
                            {
                                type: 'Accepted',
                                ...stat,
                            },
                        ],
                    );
                }
            });
        }

        console.log(data);

        return data;
    };

    const formatTimeSeriesData = (data: any) => {
        console.log(`data ===>`, data);
        const newStatus = data.filter((x: any) => x.status == 'New');
        const acceptedStatus = data.filter((x: any) => x.status == 'Accepted');
        const rejectedStatus = data.filter((x: any) => x.status == 'Rejected');

        return [newStatus, rejectedStatus, acceptedStatus];
    };

    if (isLoading || loading || huntAnalyticsCandidateStatusLoading) {
        return (
            <AuthLayout>
                <Loading />
            </AuthLayout>
        );
    }

    return (
        <AuthLayout>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Title level={3}>Billing and Subscriptions</Title>
            </div>

            <Divider />

            <div style={{ margin: '24px 0' }} className="my-4">
                <Title level={4}>Active Subscriptions</Title>

                <div className="grid-5">
                    {data?.productCodeOverallBreakdown?.map((subs: any) => (
                        <>
                            <Card
                                bordered={true}
                                style={{ width: '100%', marginLeft: '8px' }}
                                // title={subs?.code}
                            >
                                <CardBodyStats>{subs?.count || 0}</CardBodyStats>
                                <CardTitle>{subs?.code?.replaceAll('-', ' ')}</CardTitle>
                            </Card>
                        </>
                    ))}
                </div>
            </div>

            <div style={{ margin: '24px 0' }} className="my-4">
                <Title level={4}>Renewal Status</Title>

                <div className="grid-5">
                    <Card bordered={false} style={{ width: '100%', marginLeft: '8px' }}>
                        <CardBodyStats>{data?.renewalOverallBreakdown?.renewalEnabled || 0}</CardBodyStats>
                        <CardTitle>Enabled</CardTitle>
                    </Card>

                    <Card bordered={false} style={{ width: '100%', marginLeft: '8px' }}>
                        <CardBodyStats>{data?.renewalOverallBreakdown?.renewalDisabled || 0}</CardBodyStats>
                        <CardTitle>Disabled</CardTitle>
                    </Card>
                </div>
            </div>

            <Divider />

            <div>
                {/* order breakdown rgb(245, 245, 245) */}

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: 'flex-end',
                    }}
                >
                    <RangePicker
                        onChange={handleDateChange}
                        value={[moment(dateRange?.startDate), moment(dateRange?.endDate)]}
                    />
                    {/* <Button type="primary">Apply fitler</Button> */}
                </div>

                <div className="my-4">
                    <Title level={4}>Sales</Title>
                    <div>
                        <Title style={{ textAlign: 'right' }} level={5}>
                            Total order value :{' $'}
                            {data?.dayIncomeBreakdown
                                ?.map((x: any) => x.totalAmount)
                                .reduce((a: number, b: number) => a + b, 0)}
                        </Title>

                        <Column {...incomeBreakdownChart} data={getIncomeBreakdown(data)} />
                    </div>
                </div>

                <div className="my-4">
                    <Title level={4}>Subscriptions (active)</Title>
                    <div>
                        <Title style={{ textAlign: 'right' }} level={5}>
                            Total Subscriptions :{' '}
                            {data?.productCodeBreakdown
                                ?.map((x: any) => x.count)
                                .reduce((a: number, b: number) => a + b, 0)}
                        </Title>

                        <Column {...planBreakdownChart} data={data?.productCodeBreakdown} />
                    </div>
                </div>
            </div>

            <Divider style={{ marginTop: 48, marginBlock: 48 }} />

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    // marginTop: 96,
                }}
            >
                <Title level={3}>Hunt Analytics</Title>
                <RangePicker
                    onChange={handleHuntDateChange}
                    value={[moment(huntDateRange?.startDate), moment(huntDateRange?.endDate)]}
                />
            </div>

            <div className="my-4">
                <div>
                    <Title level={4}>Overall Hunt Candidates</Title>
                </div>

                <div className="grid-5">
                    <Card
                        bordered={true}
                        style={{ width: '100%' }}
                        // title={subs?.code}
                    >
                        <CardBodyStats>
                            {huntAnalytics?.fetchHuntCandidateAnalytics?.count.newApplications || 0}
                        </CardBodyStats>
                        <CardTitle>New Requests</CardTitle>
                    </Card>
                    <Card
                        bordered={true}
                        style={{ width: '100%', marginLeft: '8px' }}
                        // title={subs?.code}
                    >
                        <CardBodyStats>
                            {huntAnalytics?.fetchHuntCandidateAnalytics?.count.acceptedApplications || 0}
                        </CardBodyStats>
                        <CardTitle>Accepted Requests</CardTitle>
                    </Card>
                    <Card
                        bordered={true}
                        style={{ width: '100%', marginLeft: '8px' }}
                        // title={subs?.code}
                    >
                        <CardBodyStats>
                            {huntAnalytics?.fetchHuntCandidateAnalytics?.count.rejectedApplications || 0}
                        </CardBodyStats>
                        <CardTitle>Rejected Requests</CardTitle>
                    </Card>

                    <Card
                        bordered={true}
                        style={{ width: '100%', marginLeft: '8px' }}
                        // title={subs?.code}
                    >
                        <CardBodyStats>
                            {huntAnalyticsCandidateStatus?.getHuntAnalytics?.huntCandidatesInterviewed || 0}
                        </CardBodyStats>
                        <CardTitle>Interviewed Candidates</CardTitle>
                    </Card>
                    <Card
                        bordered={true}
                        style={{ width: '100%', marginLeft: '8px' }}
                        // title={subs?.code}
                    >
                        <CardBodyStats>
                            {huntAnalyticsCandidateStatus?.getHuntAnalytics?.huntCandidateHired || 0}
                        </CardBodyStats>
                        <CardTitle>Hired Candidates</CardTitle>
                    </Card>

                    <Card
                        bordered={true}
                        style={{ width: '100%' }}
                        // title={subs?.code}
                    >
                        <CardBodyStats>
                            {huntAnalyticsCandidateStatus?.getHuntAnalytics?.huntCandidatesRejectedAfterInterview || 0}
                        </CardBodyStats>
                        <CardTitle>Rejected Candidates</CardTitle>
                    </Card>
                    
                    <Card
                        bordered={true}
                        style={{ width: '100%' }}
                        // title={subs?.code}
                    >
                        <CardBodyStats>
                        {huntAnalytics?.fetchHuntCandidateAnalytics?.count.totalCampaigns || 0}
                        </CardBodyStats>
                        <CardTitle>Campaigns Launched</CardTitle>
                    </Card>
                </div>
            </div>

            <div className="my-4">
                <div>
                    <Line
                        {...timeSeriesConfig}
                        data={huntAnalytics?.fetchHuntCandidateAnalytics?.timeSeriesData || []}
                    />
                </div>
            </div>

            <div style={{ marginTop: 48, marginBottom: 48 }}>
                <Title level={4} style={{ marginTop: 96, marginBottom: 24 }}>
                    Country Breakdown
                </Title>

                <div>
                    <Column
                        {...countryBreakdownChart}
                        isGroup={true}
                        // data={?.newApplications}
                        data={getCampaignAndHuntStats(huntAnalytics?.fetchHuntCandidateAnalytics?.countryBreakdown)}
                        seriesField="type"
                    />
                </div>
            </div>

            <div style={{ margin: '24px 0' }} className="my-4">
                <Title level={4} style={{ marginTop: 96, marginBottom: 24 }}>
                    City Breakdown
                </Title>

                <div>
                    <Column
                        {...cityBreakdownChart}
                        isGroup={true}
                        data={getCampaignAndHuntStats(huntAnalytics?.fetchHuntCandidateAnalytics?.cityBreakdown)}
                        seriesField="type"
                    />
                </div>
            </div>

            <div style={{ margin: '24px 0' }} className="my-4">
                <Title level={4} style={{ marginTop: 96, marginBottom: 24 }}>
                    Skill Breakdown
                </Title>

                <div>
                    <Column
                        {...cityBreakdownChart}
                        isGroup={true}
                        data={getCampaignAndHuntStats(huntAnalytics?.fetchHuntCandidateAnalytics?.skillBreakdown)}
                        seriesField="type"
                    />
                </div>
            </div>
        </AuthLayout>
    );
}
