import React, { FunctionComponent } from "react";
import { Spin, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const { Text } = Typography;

const CardBodyStats: FunctionComponent = ({ children }) => (
  <Text
    style={{
      margin: "0 auto",
      textAlign: "center",
      color: "#1F2937",
      fontSize: "1.5rem",
      lineHeight: "2rem",
      display: "block",
      fontWeight: "bold",
    }}
  >
    {children}
  </Text>
);

export default CardBodyStats;
