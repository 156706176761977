import React, { FunctionComponent } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";

// routes
import { SignIn } from "./Auth/SignIn";
import { UnmoderatedCandidates } from "./Moderation/pages/UnmoderatedCandidates";
import DashboardPage from "./Analytics/pages/DashboardPage";

// apollo client
import { graphQLClient } from "./Common/api/apollo";

// styles
import "./Common/styles/index.less";


const Routes: FunctionComponent = () => {
  return (
    <ApolloProvider client={graphQLClient}>
      <Router>
        <Switch>
          <Route path="/sign-in">
            <SignIn />
          </Route>
          <Route path="/analytics">
            <DashboardPage />
          </Route>
          <Route path="/">
            <UnmoderatedCandidates />
          </Route>
        </Switch>
      </Router>
    </ApolloProvider>
  );
};

export default Routes;
