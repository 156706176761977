import create from "zustand";
import { persist } from "zustand/middleware";

type Token = string;

type TokenStoreState = {
    token?: Token

    setToken: (token: Token) => void
    resetToken: () => void
}

export const tokenStore = create<TokenStoreState>(persist(
	(set) => ({
		token: undefined,
        
		setToken: (token: Token) => {
			set({
				token,
			});
		},

		resetToken: () => {
			set({
				token: undefined,
			});
		},
	}),{ 
		name: "rooster-moderation-token-store",
	}
));