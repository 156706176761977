import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';

// helpers
import { handleTokenError, InternalGraphQLError } from '../helpers/error';

import { tokenStore } from '../stores/token.store';

const HUNT_ENDPOINT = process.env.REACT_APP_HUNT_API;
const CORE_ENDPOINT = process.env.REACT_APP_CORE_API;

const httpLink = createHttpLink({
    uri: HUNT_ENDPOINT,
});

 const coreHttpLink = createHttpLink({
    uri: CORE_ENDPOINT,
});

const errorLink = onError(({ graphQLErrors }) => {
    const tmp = graphQLErrors ? [...graphQLErrors] : [];
    handleTokenError(tmp as InternalGraphQLError[]);
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = tokenStore.getState().token;
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

export const graphQLClient = new ApolloClient({
    link: ApolloLink.from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    me: {
                        merge: true,
                    },
                },
            },
        },
    }),
});

export const coreGraphQLClient = new ApolloClient({
    link: ApolloLink.from([errorLink, authLink, coreHttpLink]),
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    me: {
                        merge: true,
                    },
                },
            },
        },
    }),
});
