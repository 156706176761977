import { gql } from '@apollo/client';

export const Queries = {
	AdminSignInMutations: gql`
		mutation AdminSignIn($data: AdminSignInInput) {
      adminSignIn(
        data: $data
      ){
        token
      }
		}
	`,
};
