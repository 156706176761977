import { gql } from '@apollo/client';

export interface AnalyticsFilterInInput {
    startDate: string;
    endDate: string;
}
export interface AnalyticsFilterInput {
    startDate: string;
    endDate: string;
}

export const Queries = {
    fetchHuntCandidateAnalytics: gql`
        query fetchHuntCandidateAnalytics($filters: AnalyticsFilterInInput) {
            fetchHuntCandidateAnalytics(filters: $filters) {
                timeSeriesData {
                    date
                    count
                    status
                }
                skillBreakdown {
                    newApplications {
                        name
                        count
                    }
                    rejectedApplications {
                        name
                        count
                    }
                    acceptedApplications {
                        name
                        count
                    }
                }

                count {
                    newApplications
                    rejectedApplications
                    acceptedApplications
                    totalCampaigns
                    totalApplications
                }
                countryBreakdown {
                    newApplications {
                        name
                        count
                    }
                    rejectedApplications {
                        name
                        count
                    }
                    acceptedApplications {
                        name
                        count
                    }
                }

                cityBreakdown {
                    newApplications {
                        name
                        count
                    }
                    rejectedApplications {
                        name
                        count
                    }
                    acceptedApplications {
                        name
                        count
                    }
                }
            }
        }
    `,
    fetchHuntCandidateStatusAnalytics: gql`
        query fetchHuntCandidateAnalytics($filters: AnalyticsFilterInput) {
            getHuntAnalytics(filters: $filters) {
                huntCandidatesRejectedAfterInterview
                huntCandidatesInterviewed
                huntCandidateHired
            }
        }
    `,
};
