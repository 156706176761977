import React, { FunctionComponent, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Table, Tag, Typography, Button, message, Empty, Divider } from "antd";

// partials
import { AuthLayout } from "../../Common/partials/Layout";
import Loading from "../../Common/partials/Loading";

// containers
import { HuntCandidateModal } from "../containers/HuntCandidateModal";

// Queries
import { Queries as ModerationQueries, Mutations as ModerationMutations } from "../../Common/queries/moderation";

// types
import { HuntCandidate } from "../../Common/types/HuntCandidate";

const { Title } = Typography;

interface ModeratedCandidatesResponse{
  fetchUnmoderatedHuntCandidates: Partial<HuntCandidate>[];
}


export const UnmoderatedCandidates: FunctionComponent = () => {
  const { data, loading, refetch } = useQuery<ModeratedCandidatesResponse>(ModerationQueries.getUnmoderatedCandidateQueue);
  const [ approveCandidate, { loading: approving }] = useMutation(ModerationMutations.approveHuntCandidate);
  const [ rejectHuntCandidate, { loading: rejecting }] = useMutation(ModerationMutations.rejectHuntCandidate);

  const [ selectedCandidate, setSelectedCandidate ] = useState<Partial<HuntCandidate> | null>();
  const [ selectedIndex, setSelectedIndex ] = useState<number | null>();

  if (loading || !data) {
    return (
      <AuthLayout>
        <Loading />
      </AuthLayout>
    )
  }

  const CandidateNameColumn = (re: unknown, record: Partial<HuntCandidate>) => (
    <Button type="link" onMouseDown={() => {
      const curIndex = data ? data.fetchUnmoderatedHuntCandidates.findIndex((c) => c.id === record.id) : -1;
      console.log(curIndex);
      setSelectedCandidate(record);
      if (curIndex > -1) {
        setSelectedIndex(curIndex);
      }
    }}>{record.firstName} {record.lastName}</Button>
  );

  const CandidateSkills = (re: unknown, record: Partial<HuntCandidate>) => (
    record.skills ? record.skills?.map((skill, i) => (
      <Tag key={i}>{skill.skill} - {skill.noOfYears}</Tag>
    )) : []
  )

  const columns = [
    {
      title: "Candidate Name",
      key: "candidateName",
      render: CandidateNameColumn,
    },
    {
      title: "Primary Role",
      key: "primaryRole",
      dataIndex: "primaryRole",
    },
    {
      title: "Location",
      key: "location",
      dataIndex: "location",
    },
    {
      title: "Skills",
      key: "skills",
      render: CandidateSkills,
    }
  ];

  
  return (
    <AuthLayout>
      <Title level={3} >Unmoderated candidates</Title>
      <Divider />
      <div>
        {data.fetchUnmoderatedHuntCandidates.length > 0 ? (
          <Table 
            columns={columns}
            dataSource={data.fetchUnmoderatedHuntCandidates}
            pagination={false}
            rowKey="id"
          />
        ) : (
          <Empty 
            description="There aren't any candidates pending moderation right now"
          />
        )}
      </div>
      {selectedCandidate ? (
        <HuntCandidateModal 
          candidate={selectedCandidate}
          approving={approving}
          onApprove={async (candidate) => {
            const curIndex = selectedIndex;

            try {
              await approveCandidate({
                variables: {
                  data: {
                    huntCandidateId: candidate.id,
                  }
                }
              });

              message.success(`You approved ${candidate.firstName} ${candidate.lastName}'s profile`, 4);

              if (curIndex !== null && curIndex !== undefined && (curIndex + 1 < data.fetchUnmoderatedHuntCandidates.length)) {
                const tmp = data.fetchUnmoderatedHuntCandidates[curIndex + 1];
                console.log("NEXT");
                console.log(tmp);
                setSelectedIndex(curIndex + 1);
                setSelectedCandidate(tmp);
              } else {
                setSelectedIndex(null);
                setSelectedCandidate(null);
                refetch();
              }
            } catch (e) {
              message.error(e.message);
            }
          }}
          onReject={async (candidate, reasons) => {
            const curIndex = selectedIndex;

            try {
              await rejectHuntCandidate({
                variables: {
                  data: reasons,
                }
              });

              message.success(`You rejected ${candidate.firstName} ${candidate.lastName}`, 4);

              if (curIndex !== null && curIndex !== undefined && (curIndex + 1 < data.fetchUnmoderatedHuntCandidates.length)) {
                const tmp = data.fetchUnmoderatedHuntCandidates[curIndex + 1];
                console.log("NEXT");
                console.log(tmp);
                setSelectedIndex(curIndex + 1);
                setSelectedCandidate(tmp);
              } else {
                setSelectedIndex(null);
                setSelectedCandidate(null);
                refetch();
              }
            } catch (e) {
              message.error(e.message, 4);
            }
          }}
          onClose={() => {
            setSelectedCandidate(null);
            setSelectedIndex(null);
            refetch();
          }}
        />
      ) : null}
    </AuthLayout>
  )
}