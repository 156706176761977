import React, { FunctionComponent } from "react";
import { Spin, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const { Title } = Typography;

const CardTitle: FunctionComponent = ({ children }) => (
  <Title
    style={{
      // margin: "0 auto",
      color: "#6B7280",
      textAlign: "center",
      textTransform: "capitalize",
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
    }}
  >
    {children}
  </Title>
);

export default CardTitle;
