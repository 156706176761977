import React, { FunctionComponent } from "react";
import { Form, Input, Button, message } from "antd";
import { Formik, FormikErrors } from 'formik';
import { useMutation } from '@apollo/client';

// stores
import { tokenStore } from '../Common/stores/token.store';

// partials
import { Layout } from "../Common/partials/Layout";

// Queries
import { Queries as AuthQueries } from "../Common/queries/auth";

interface AdminSignInInput{
	username: string;
	password: string;
};

interface AdminSignInResponse{
  adminSignIn: {
    token: string;
  }
}

export const SignIn: FunctionComponent = () => {
	const setToken = tokenStore((state) => state.setToken);

	const [ signIn, { loading } ] = useMutation<AdminSignInResponse, { data: AdminSignInInput }>(AuthQueries.AdminSignInMutations);

  return (
    <Layout>
      <Formik
        initialValues={{
          username: '',
          password: '',
        } as AdminSignInInput}
        validate={(values) => {
          const errors: FormikErrors<AdminSignInInput> = {};

          if (!values.username) errors.username = 'Required';
          if (!values.password) errors.password = 'Required';

          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);

          try {
            const { data } = await signIn({
              variables: {
                data: {
                  username: values.username,
                  password: values.password,
                }
              }
            });

            if (data) {
              setToken(data.adminSignIn.token);
            }
          } catch (e) {
            message.error(e.message, 4);
          }
          console.log(values);
        }}
      >
        {({ values, handleChange, handleSubmit }) => (
          <Form layout="vertical" colon={false} onFinish={handleSubmit}>
            <Form.Item label="Username">
              <Input name="username" onChange={handleChange} value={values.username} />
            </Form.Item>
            <Form.Item label="Password">
              <Input type="password" name="password" onChange={handleChange} value={values.password} />
            </Form.Item>
            <Button type="primary" block htmlType="submit" loading={loading}>
              Sign In
            </Button>
          </Form>
        )}
      </Formik>
    </Layout>
  )
}