import React, { FunctionComponent, useState } from "react";
import { Modal, Row, Col, Descriptions, Tag, Divider, Button, Form, Input, Select, Table, Typography } from "antd";
import moment from "moment";
import { Formik, FormikErrors } from "formik";

// types
import { HuntCandidate, RejectHuntCandidateInput } from "../../Common/types/HuntCandidate";

// Queries
import { Queries as ModerationQueries } from "../../Common/queries/moderation";

const { Option } = Select;
const { TextArea } = Input;

const Iframe = ({ iframe }: { iframe: string }) => (
	<div dangerouslySetInnerHTML={{ __html: iframe ? iframe : '' }} />
);

interface HuntCandidateModalProps{
  candidate: Partial<HuntCandidate>;

  approving: boolean;

  onApprove: (candidate: Partial<HuntCandidate>) => void;
  onReject: (candidate: Partial<HuntCandidate>, rejectionReasons: RejectHuntCandidateInput) => void;

  onClose: () => void;
}

export const HuntCandidateModal: FunctionComponent<HuntCandidateModalProps> = (props: HuntCandidateModalProps) => {
  const { candidate, approving, onApprove, onReject, onClose } = props;
  const [ showRejectionModal, setShowRejectionModal ] = useState(false);

  return (
    <>
      <Modal
        visible={true}
        onCancel={onClose}
        destroyOnClose
        title={(
          <div>
            <span>
              {candidate.firstName} {candidate.lastName}
            </span>
            <span className="timestamp-tag">Created On {moment(candidate.createdAt).format("MMMM Do YYYY")}</span>
          </div>
        )}
        width={1000}
        footer={(
          <div>
            <Button type="primary" danger onMouseDown={() => setShowRejectionModal(true)}>Reject</Button>
            <Button type="primary" onMouseDown={() => onApprove(candidate)} loading={approving}>Approve</Button>
          </div>
        )}
      >
        <Row
          gutter={32}
        >
          <Col flex={1}>
            <Iframe
              iframe={`<iframe src="${candidate.resumeUrl}" width="100%" height="600" style="border:0;"></iframe>`}
            />
          </Col>
          <Col span={12}>
            <Descriptions bordered>
              <Descriptions.Item label="Primary Role" span={3}>{candidate.primaryRole}</Descriptions.Item>
              {candidate.secondaryRoles ? (
                <Descriptions.Item label="Secondary Roles" span={3}>
                  {candidate.secondaryRoles.map((role, i) => (
                    <Tag key={i} style={{ textTransform: "capitalize" }}>{role}</Tag>
                  ))}
                </Descriptions.Item>
              ) : null} 
              {candidate.skills ? (
                <Descriptions.Item label="Skills" span={3}>
                  {candidate.skills.map((skill, i) => (
                    <Tag key={i} style={{ textTransform: "capitalize" }}>{skill.skill} - {skill.noOfYears}</Tag>
                  ))}
                </Descriptions.Item>
              ) : null} 
              <Descriptions.Item label="Location" span={3}>{candidate.location}</Descriptions.Item>
              <Descriptions.Item label="Past Responsibilities" span={3}>{candidate.pastResponsibilities}</Descriptions.Item>
            </Descriptions>
            <Divider />
            <Descriptions bordered title="Expectations">
              <Descriptions.Item label="Salary Expectations" span={3}>
                {candidate.expectedSalaryCurrency} {candidate.expectedSalaryAmount?.toLocaleString()} / {candidate.expectedSalaryFrequency}{candidate.salaryFlexible ? <Tag>Flexible</Tag> : null}
              </Descriptions.Item>
              <Descriptions.Item label="Preferred Work Style" span={3}>{candidate.preferredWorkStyle}</Descriptions.Item>
              <Descriptions.Item label="Currently Employed" span={3}>{candidate.currentlyEmployed ? "Yes" : "No"}</Descriptions.Item>
              <Descriptions.Item label="Job Search Status" span={3} style={{ textTransform: "capitalize" }}>{candidate.jobSearchStatus}</Descriptions.Item>
            </Descriptions>
            {candidate.rejectionNotes && candidate.rejectionNotes.length > 0 ? (
              <>
                <Divider />
                <Typography.Title level={5}>Rejection Notes</Typography.Title>
                <Table 
                  dataSource={candidate.rejectionNotes}
                  columns={[
                    {
                      title: "Timestamp",
                      key: "timestamp",
                      dataIndex: "timestamp",
                      render: (val) => <span>{moment(val).format("MMMM Do YYYY")}</span>
                    },
                    {
                      title: "Category",
                      key: "category",
                      dataIndex: "category",
                    },
                    {
                      title: "Description",
                      key: "description",
                      dataIndex: "description",
                    }
                  ]}
                  pagination={false}
                />
              </>
            ) : null}
          </Col>
        </Row>
      </Modal>
      {showRejectionModal ? (
        <Formik
          enableReinitialize
          initialValues={{
            huntCandidateId: candidate.id,
            rejectionCategory: undefined,
            rejectionDescription: undefined,
          } as RejectHuntCandidateInput}
          onSubmit={(values) => {
            onReject(candidate, values);
            console.log(values);
          }}
          validate={(values) => {
            const errors: FormikErrors<RejectHuntCandidateInput> = {};
            if (!values.rejectionCategory) errors.rejectionCategory = "Required";
            if (!values.rejectionDescription) errors.rejectionDescription = "Required";

            return errors;
          }}
        >
          {({ values, errors, setFieldValue, handleChange, submitForm }) => (
            <Modal
              visible={showRejectionModal}
              title="Rejection Notice"
              okButtonProps={{
                type: "primary",
                danger: true,
              }}
              onOk={() => {
                submitForm();
              }}
              onCancel={() => {
                setShowRejectionModal(false);
              }}
              destroyOnClose
              okText="Reject Candidate"
            >
              <Form layout="vertical">
                <Form.Item 
                  label="Rejection Reason"
                  required
                  help={errors.rejectionCategory}
                  validateStatus={errors.rejectionCategory ? 'error' : ''}
                >
                  <Select
                    value={values.rejectionCategory} 
                    onChange={(val) => {
                      setFieldValue('rejectionCategory', val);
                    }}
                  >
                    <Option value="Skillset doesn't match salary expectations">{"Skillset doesn't match salary expectations"}</Option>
                    <Option value="CV invalid format">{"CV invalid format"}</Option>
                    <Option value="Description too vague">{"Description too vague"}</Option>
                    <Option value="Description and work experience doesn't match CV">{"Description and work experience doesn't match CV"}</Option>
                  </Select>
                </Form.Item>
                <Form.Item 
                  label="Message to Candidate"
                  required
                  help={errors.rejectionDescription}
                  validateStatus={errors.rejectionDescription ? 'error' : ''}
                >
                  <TextArea rows={4} name="rejectionDescription" value={values.rejectionDescription} onChange={handleChange} />
                </Form.Item>
              </Form>
            </Modal>
          )}
        </Formik>
      ) : null}
    </>
  )
}