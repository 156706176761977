import { gql } from '@apollo/client';

export const Queries = {
	getUnmoderatedCandidateQueue: gql`
		query FetchUnmoderatedHuntCandidates {
      fetchUnmoderatedHuntCandidates{
        id
        firstName
        lastName
        approved
        createdAt
        updatedAt
        location
        resumeUrl
        primaryRole
        secondaryRoles
        pastResponsibilities
        currentlyEmployed
        preferredWorkStyle
        startDate
        jobSearchStatus
        skills{
          skill
          noOfYears
        }
        expectedSalaryCurrency
        expectedSalaryAmount
        expectedSalaryFrequency
        rejectionNotes{
          category
          description
          timestamp
        }
      }
		}
	`,
};

export const Mutations = {
  approveHuntCandidate: gql`
    mutation ApproveHuntCandidate($data: ApproveHuntCandidateInput){
      approveHuntCandidate(data: $data)
    }
  `,
  rejectHuntCandidate: gql`
    mutation RejectHuntCandidate($data: RejectHuntCandidateInput){
      rejectHuntCandidate(data: $data)
    }
  `,
}