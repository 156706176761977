export interface InternalGraphQLError{
  extensions: {
    code?: string;
    exception?: {
      code?: string;
    }
  }
}

export const handleTokenError = (errors: InternalGraphQLError[]): void => {
	for (let error of errors) {
		if (error.extensions.exception && error.extensions.exception.code === "INVALID_AUTH_TOKEN") {
			window.location.replace("/sign-out");
			break;
		}
	}
};