import React, { FunctionComponent, ReactNode } from "react";
import { Redirect } from "react-router-dom";
import { Button } from "antd";

// store
import { tokenStore } from "../stores/token.store";

// assets
import logo from "../assets/logo.svg";

interface LayoutProps {
  children: ReactNode;
}

export const AuthLayout: FunctionComponent<LayoutProps> = ({
  children,
}: LayoutProps) => {
  const token = tokenStore((state) => state.token);
  const resetToken = tokenStore((state) => state.resetToken);

  if (!token) {
    return <Redirect to="/sign-in" />;
  }

  return (
    <div>
      <header>
        <div className="container">
          <img src={logo} className="logo" />
          <nav>
            <Button type="link" href="/Moderation">
              Moderation
            </Button>
            <Button type="link" href="/analytics">
              Analytics
            </Button>
            <Button
              type="link"
              onMouseDown={() => {
                resetToken();
              }}
            >
              Sign Out
            </Button>
          </nav>
        </div>
      </header>
      <div>
        <div className="container">{children}</div>
      </div>
    </div>
  );
};

export const Layout: FunctionComponent<LayoutProps> = ({
  children,
}: LayoutProps) => {
  const token = tokenStore((state) => state.token);

  if (token) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <div className="auth-container">{children}</div>
    </div>
  );
};
